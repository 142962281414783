import React, { useEffect, useRef, useState } from "react";
import Topbar from "../../layout/Topbar";
import RadRun from "../../assets/images/radrun1.png";
import Routing from "../../assets/icon/routing.png";
import { useParams } from "react-router-dom";
import {
  fetchEventById,
  markEventComplete,
} from "../../redux1/Slices/eventSlice";
import { useDispatch, useSelector } from "react-redux";
import GoogleMap from "../Googlemap";
import ContentCard from "../../components/cards/ContentCard";
import { Card } from "primereact/card";
import upcomingEventImage from "../../assets/icon/upcoming-event.png";
import PrimaryButton from "../../components/buttons/button";
import CustomTable from "../../components/table/table";
import { TableEventDetails } from "./LatestEvents/TableEventDetails";
import { ReactComponent as Empty } from "../../assets/svg/empty.svg";
import QRScanner from "../../components/QrScanner/QrScanner";
import { createRun, getRadRunByEventId } from "../../redux1/Slices/radRunSlice";
import ConfirmDailog from "../../components/overlay/Dialog";
import { imagePath } from "../../utils/commonFunctions";
import { useDebounce } from "primereact/hooks";
import { InputMask } from "primereact/inputmask";
import moment from "moment-timezone";
import beep from "../../assets/audio/beep.mp3";

const EventInfo = () => {
  const dispatch = useDispatch();
  const [eventData, setEventData] = useState();
  const radRunData = useSelector((state) => state.radRun.radRunByEventData);
  const [address, setAddress] = useState();
  const { id } = useParams();
  const { EventColumns } = TableEventDetails();
  const [isScannerOpen, setIsScannerOpen] = useState(false);
  const [dialog, setDialog] = useState({ visible: false });
  const [inputSearch, debouncedValue, setSearchValue] = useDebounce("", 800);
  const [filterData, setFilterData] = useState({
    searchKeys: JSON.stringify(["child.firstName", "child.lastName"]),
    searchString: "",
  });
  const [eventTimeInSeconds, setEventTimeInSeconds] = useState(1200); // Total time in seconds
  const [eventTime, setEventTime] = useState("20:00");
  const [countdownTimeInSeconds, setCoundownTimeInSeconds] = useState(600); // Total time in seconds
  const [countDownTime, setCountdownTime] = useState("10:00");
  const [isCountdownTimerRunning, setIsCountdownTimerRunning] = useState(false); // Control timer state
  const [isEventTimerRunning, setIsEventTimerRunning] = useState(false); // Control timer state

  const [isGo, setIsGo] = useState(false);
  const [isEventCompleted, setIsEventCompleted] = useState(false);
  const [isOnYourMarks, setIsOnYourMarks] = useState(false);

  const emptyMessage = () => {
    return (
      <div className="flex flex-column align-items-center justify-content-center pt-3 md:pt-8">
        <Empty />
        <h2 className="inter">Not Found</h2>
      </div>
    );
  };

  useEffect(() => {
    dispatch(fetchEventById({ id, setEventData }));
  }, [id]);

  useEffect(() => {
    dispatch(getRadRunByEventId({ id, filterData }));
  }, [id, filterData]);

  useEffect(() => {
    setFilterData({
      ...filterData,
      searchString: debouncedValue,
    });
  }, [debouncedValue]);

  const handleScan = (childId) => {
    const data = {
      childId,
      eventId: id,
    };
    dispatch(createRun({ data, setIsScannerOpen }));
    // }
  };

  useEffect(() => {
    if (eventData?.address) setAddress(eventData?.address);
  }, [eventData]);

  const upcomingEventHeader = (
    <div className="flex justify-content-center mt-4">
      <img alt="Card" src={upcomingEventImage} style={{ width: "30%" }} />
    </div>
  );

  // Function to start the countdown
  const startCountdownTimer = () => {
    setIsGo(false);
    setIsScannerOpen(true);
    const [countdownMinutes, countdownSeconds] = countDownTime
      .split(":")
      .map(Number);

    const totalCountdownTime = countdownMinutes * 60 + countdownSeconds;

    setCoundownTimeInSeconds(totalCountdownTime); // Set the total time
    setIsCountdownTimerRunning(true); // Start the countdown
  };

  // Function to start the countdown
  const startEventTimer = () => {
    const [eventmMinutes, eventSeconds] = eventTime.split(":").map(Number); // Parse MM:SS

    const totalEventTime = eventmMinutes * 60 + eventSeconds; // Convert to total seconds

    setEventTimeInSeconds(totalEventTime);
    setIsEventTimerRunning(true); // Start the countdown
  };

  useEffect(() => {
    const audio = new Audio(beep);

    if (!isCountdownTimerRunning) return; // Stop if time reaches 0 or not running
    if (countdownTimeInSeconds <= 0 && isCountdownTimerRunning) {
      setIsCountdownTimerRunning(false);
      startEventTimer();
    } // Stop if time reaches 0 or not running

    const timer = setInterval(() => {
      setCoundownTimeInSeconds((prevTime) => {
        const newTime = prevTime - 1;
        if (newTime >= 0) {
          audio.play().catch((error) => {
            console.error("Audio playback failed:", error);
          });
        }
        return newTime;
      }); // Decrease by 1 second
    }, 1000);

    return () => clearInterval(timer); // Clear the interval on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdownTimeInSeconds, isCountdownTimerRunning]);

  useEffect(() => {
    const audio = new Audio(beep);

    if (!isEventTimerRunning) return; // Stop if time reaches 0 or not running
    if (eventTimeInSeconds <= 0 && isEventTimerRunning) {
      setIsEventTimerRunning(false);
      // dispatch(markEventComplete({ id, setEventData, setDialog }));
      setIsEventCompleted(true);
    }

    const timer = setInterval(() => {
      setEventTimeInSeconds((prevTime) => {
        const newTime = prevTime - 1;
        if (newTime >= 0) {
          audio.play().catch((error) => {
            console.error("Audio playback failed:", error);
          });
        }
        return newTime;
      }); // Decrease by 1 second
    }, 1000);

    return () => clearInterval(timer); // Clear the interval on unmount
  }, [eventTimeInSeconds, isEventTimerRunning]);

  // Format time into MM:SS
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  const closeOnYourMarksScreen = () => {
    setIsCountdownTimerRunning(false);
    setIsEventTimerRunning(false);
    setIsGo(false);
    setIsEventCompleted(false);
    setIsOnYourMarks(false);
  };

  return (
    <>
      <Topbar></Topbar>
      <ConfirmDailog dialog={dialog} setDialog={setDialog} />
      <div className="content-box px-2 pb-3">
        <h1 className="kagitingan lg:text-5xl text-xl">{eventData?.name}</h1>
        <div className="grid">
          <div className="col-12 md:col-6">
            <div>
              <img
                className="border-round-top-lg"
                src={eventData?.image ? imagePath(eventData?.image) : RadRun}
                alt=""
                style={{
                  width: "100%",
                  height: "25em",
                }}
              />
              <div
                className="flex flex-column p-3 line-height-4 border-round-bottom-lg bg-gray-100"
                style={{ marginTop: "-5px" }}
              >
                <span className="flex align-content-center text-sm">
                  <i className="pi pi-calendar mr-2 text-xl  text-blue"></i>
                  {eventData &&
                    moment(eventData?.date)
                      .tz(eventData?.timeZone)
                      .format("ddd DD/MM/YYYY")}
                </span>
                <span className="flex align-items-center text-sm  inline-block ">
                  <i className="pi pi-clock mr-2 text-xl  text-blue"></i>
                  At{" "}
                  {eventData &&
                    moment(eventData?.time)
                      .tz(eventData?.timeZone)
                      .format("hh:mm A")}{" "}
                  {eventData?.timeZone ?? ""}
                </span>
                <span className="flex align-items-center line-height-3 my-2">
                  <i className="pi pi-map-marker mr-2 text-xl  text-blue"></i>
                  <span className="text-sm ">{address?.name}</span>
                </span>
                <span className="flex align-items-center inline-block ">
                  <img src={Routing} alt="" />
                  <span className="text-sm ml-2">
                    {eventData?.loopDistance
                      ? eventData?.loopDistance + " m"
                      : ""}
                  </span>
                </span>
                <p className="subheading pointer-events-none">
                  <div
                    style={{ textAlign: "justify" }}
                    contentEditable="true"
                    dangerouslySetInnerHTML={{ __html: eventData?.description }}
                  ></div>
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 md:col-6 md:w-5 mx-auto mb-3 md:mb-0">
            <div className="flex flex-column gap-4">
              <GoogleMap address={address} />
              {eventData &&
              moment(new Date(eventData?.date))
                .tz(eventData?.timeZone)
                .isSame(moment(new Date()), "day") &&
              !eventData.isCompleted ? (
                <PrimaryButton
                  className={
                    "w-full h-7rem border-round-3xl text-3xl p-0 bg-red-500"
                  }
                  extraClass={"text-right"}
                  label={"On Your Marks"}
                  onClick={() => setIsOnYourMarks(true)}
                ></PrimaryButton>
              ) : null}{" "}
            </div>
          </div>
        </div>
        <div className="md:flex justify-content-between align-items-center">
          <h1 className="kagitingan lg:text-5xl text-xl px-1">Stats</h1>
        </div>
        {eventData &&
        moment(new Date(eventData?.date))
          .tz(eventData?.timeZone)
          .isAfter(moment(new Date()), "day") &&
        !eventData.isCompleted ? (
          <div className="card flex justify-content-center">
            <Card className="w-full" title="" header={upcomingEventHeader}>
              <p className="m-0 text-center">
                The event has not taken place yet. The statistics will appear
                after the event has occurred.
              </p>
            </Card>
          </div>
        ) : (
          <ContentCard
            heading={eventData?.name}
            inputSearchExtraClass="w-full"
            headingSize={"text-center md:text-left text-2xl"}
            inputSearch={inputSearch}
            setSearch={setSearchValue}
            filterData={filterData}
            setFilterData={setFilterData}
            placeholder={"Children"}
          >
            <CustomTable
              columns={EventColumns}
              emptyMessage={emptyMessage}
              data={radRunData}
            />
          </ContentCard>
        )}
      </div>

      {isOnYourMarks ? (
        <div className="fullscreen-overlay">
          <i
            className="pi pi-times cursor-pointer text-white right-0 absolute p-3"
            onClick={closeOnYourMarksScreen}
          />
          <div className="flex flex-column h-full w-full p-8 gap-3 justify-content-center">
            {isEventCompleted ? (
              <div
                className={
                  "w-full p-4 flex align-items-center font-bold kagitingan flex-column justify-content-center border-round-3xl text-white text-8xl p-0 bg-pink"
                }
              >
                <h1 className="text-center kagitingan">End Of Event</h1>
              </div>
            ) : isCountdownTimerRunning ? (
              <>
                <div className="flex flex-row gap-2 align-items-center">
                  <div
                    className={
                      "w-6 p-4 flex align-items-center font-bold kagitingan flex-column justify-content-center border-round-3xl text-white text-4xl p-0 bg-red-500"
                    }
                  >
                    <h1 className="text-center kagitingan">Countdown Time</h1>
                    <h1 className="text-8xl kagitingan m-0">
                      {formatTime(countdownTimeInSeconds)}
                    </h1>
                  </div>
                  <div className="w-6 flex flex-column gap-2 justify-content-center align-items-center">
                    <QRScanner
                      visible={isScannerOpen}
                      onScan={(e) => {
                        handleScan(e._id);
                      }}
                      onHideScanner={() => setIsScannerOpen(false)}
                    />
                    <div
                      className={
                        "p-4 font-bold kagitingan flex-column border-round-3xl text-white bg-red-500"
                      }
                    >
                      <h1 className="text-6xl kagitingan m-0">
                        Scan your qr code above
                      </h1>
                    </div>
                  </div>
                </div>
              </>
            ) : isEventTimerRunning ? (
              <>
                <div className="flex flex-row gap-2 align-items-center">
                  <div
                    className={
                      "w-6 p-4 flex align-items-center font-bold kagitingan flex-column justify-content-center border-round-3xl text-white text-4xl p-0 bg-pink"
                    }
                  >
                    <h1 className="text-center kagitingan">Event Time</h1>
                    <h1 className="text-8xl kagitingan m-0">
                      {formatTime(eventTimeInSeconds)}
                    </h1>
                  </div>
                  <div className="w-6 flex flex-column gap-2 justify-content-center align-items-center">
                    <QRScanner
                      visible={isScannerOpen}
                      onScan={(e) => {
                        handleScan(e._id);
                      }}
                      onHideScanner={() => setIsScannerOpen(false)}
                    />
                    <div
                      className={
                        "p-4 font-bold kagitingan flex-column border-round-3xl text-white bg-pink"
                      }
                    >
                      <h1 className="text-6xl kagitingan m-0">
                        Scan your qr code above
                      </h1>
                    </div>
                  </div>
                </div>
              </>
            ) : isGo ? (
              <div className="flex flex-row w-full">
                <PrimaryButton
                  className={
                    "w-6 h-7rem border-round-3xl text-4xl p-0 bg-pink border-none kagitingan"
                  }
                  extraClass={"text-center"}
                  label={"Go!!!"}
                  onClick={startCountdownTimer}
                ></PrimaryButton>
              </div>
            ) : (
              <>
                <div className="flex flex-row gap-2">
                  <div
                    className={
                      "w-full p-4 flex align-items-center font-bold kagitingan flex-column justify-content-center border-round-3xl text-white text-4xl p-0 bg-primary-blue"
                    }
                  >
                    Countdown Time
                    <div className="flex flex-wrap align-items-end">
                      <InputMask
                        id="ssn"
                        value={formatTime(countdownTimeInSeconds)}
                        mask="99:99"
                        unstyled={true}
                        className="w-6 timer-input border-none text-4xl underline text-right font-bold text-white kagitingan bg-primary-blue"
                        placeholder="20:00"
                        variant="outlined"
                        onChange={(e) => setCountdownTime(e.target.value)}
                      ></InputMask>
                      <span className="underline">minutes</span>
                    </div>
                  </div>
                  <div
                    className={
                      "w-full p-4 border-round-3xl flex align-items-center font-bold kagitingan flex-column justify-content-center text-4xl text-white p-0 bg-primary-blue"
                    }
                  >
                    Event Time
                    <div className="flex flex-wrap align-items-end">
                      <InputMask
                        id="ssn"
                        value={formatTime(eventTimeInSeconds)}
                        mask="99:99"
                        unstyled={true}
                        className="w-6 timer-input border-none text-4xl underline text-right font-bold text-white kagitingan bg-primary-blue"
                        placeholder="20:00"
                        variant="outlined"
                        onChange={(e) => setEventTime(e.target.value)}
                      ></InputMask>
                      <span className="underline">minutes</span>
                    </div>
                  </div>
                </div>

                <div className="flex flex-row w-full">
                  <PrimaryButton
                    className={
                      "w-6 h-7rem border-round-3xl text-4xl p-0 bg-primary-blue"
                    }
                    extraClass={"text-right"}
                    label={"Get Set"}
                    onClick={setIsGo}
                  ></PrimaryButton>
                </div>
              </>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default EventInfo;
